import { createRouter, createWebHistory, } from "vue-router"
import HomeView from "../views/HomeView.vue"
import AboutView from "../views/AboutView.vue"
import WhyUsView from "../views/WhyUsView.vue"
import SelectionCustomersView from "../views/SelectionCustomersView.vue"
import ImportExportView from "../views/ImportExportView.vue"
import LogisticsView from "../views/LogisticsView.vue"
import MarketView from "../views/MarketView.vue"
import NewsView from "../views/NewsView.vue"

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/about-us",
        name: "about",
        component: AboutView,
    },
    {
        path: "/why-choose-us",
        name: "why-us",
        component: WhyUsView,
    },
    {
        path: "/selection-of-customers-and-partners-in-malaysia-and-asean",
        name: "selection",
        component: SelectionCustomersView,
    },
    {
        path: "/import-and-export-facilitation-through-malaysia",
        name: "import-export",
        component: ImportExportView,
    },
    {
        path: "/logistics-and-customs-clearance-solution",
        name: "logistics",
        component: LogisticsView,
    },
    {
        path: "/market-research-and-analysis-for-export-activities",
        name: "market",
        component: MarketView,
    },
    {
        path: "/news/:id",
        name: "news",
        component: NewsView,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.hash) {
      const anchor = to.hash.replace(/^#/, '');
      if (anchor) {
        const anchorLink = document.getElementById(anchor);
        if (anchorLink) {
          anchorLink.scrollIntoView();
        }
      }
    }
    next();
  });

export default router
