<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-65e0c967 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="65e0c967" data-element_type="section" id="about" data-settings="{&quot;background_background&quot;:&quot;slideshow&quot;,&quot;background_slideshow_gallery&quot;:[],&quot;background_slideshow_loop&quot;:&quot;yes&quot;,&quot;background_slideshow_slide_duration&quot;:5000,&quot;background_slideshow_slide_transition&quot;:&quot;fade&quot;,&quot;background_slideshow_transition_duration&quot;:500}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6141cf8f" data-id="6141cf8f" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-421ab9b6 elementor-invisible elementor-widget elementor-widget-heading" data-id="421ab9b6" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInUp&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default" style="text-align: center;">{{ this.$langStore.lang == 'en' ? 'OUR ' : 'НАШИ ' }}<span style="color:#445c71">{{ this.$langStore.lang == 'en' ? 'ADVANTAGES' : 'ПРЕИМУЩЕСТВА' }} </span></h2>		</div>
				</div>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-60442773 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="60442773" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default" style="text-align: center;">
					<div class="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-4d0525da elementor-invisible" data-id="4d0525da" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-5d82fedc elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="5d82fedc" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
			<link rel="stylesheet" href="wp-content/plugins/elementor/assets/css/widget-icon-box.min.css">		<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-far-lightbulb" viewBox="0 0 352 512" xmlns="http://www.w3.org/2000/svg"><path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						{{ this.$langStore.lang == 'en' ? 'Innovation' : 'Инновации' }}					</span>
				</h3>
									<p class="elementor-icon-box-description" style="text-align: justify;">
										{{ this.$langStore.lang == 'en' ? 'Innovative strategies foster market adaptability, driving success in Malaysia\'s dynamic trade landscape.' : 'Инновационные стратегии способствуют адаптации рынка, обеспечивая успех в динамичной торговой среде Малайзии.' }}					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-2f34758e elementor-invisible" data-id="2f34758e" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-259c8c0c elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="259c8c0c" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-far-thumbs-up" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						{{ this.$langStore.lang == 'en' ? 'Quality' : 'Качество' }}					</span>
				</h3>
									<p class="elementor-icon-box-description" style="text-align: justify;">
										{{ this.$langStore.lang == 'en' ? 'Quality commitment ensures consumer satisfaction, paramount for thriving in Malaysian markets' : 'Приверженность качеству обеспечивает удовлетворенность потребителей, что имеет первостепенное значение для процветания на рынках Малайзии.' }}.					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-7f5749ba elementor-invisible" data-id="7f5749ba" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-7e81e3e7 elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="7e81e3e7" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-far-clock" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						{{ this.$langStore.lang == 'en' ? 'Experience' : 'Опыт' }}					</span>
				</h3>
									<p class="elementor-icon-box-description" style="text-align: justify;">
										{{ this.$langStore.lang == 'en' ? 'Extensive experience informs decision-making, crucial for navigating Malaysia\'s trade ecosystem.' : 'Обширный опыт влияет на принятие решений, имеющих решающее значение для навигации по торговой экосистеме Малайзии.' }}					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-7ee46f6a elementor-invisible" data-id="7ee46f6a" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-771d7be4 elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="771d7be4" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-fas-users" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						{{ this.$langStore.lang == 'en' ? 'Super Team' : 'Супер команда' }}					</span>
				</h3>
									<p class="elementor-icon-box-description" style="text-align: justify;">
										{{ this.$langStore.lang == 'en' ? 'Team cultivates collaboration, for achieving collective objectives in Malaysia\'s market.' : 'Команда развивает сотрудничество для достижения коллективных целей на рынке Малайзии.' }}					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-228b2079 elementor-invisible" data-id="228b2079" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-2c692d42 elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="2c692d42" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-fab-rocketchat" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M284.046,224.8a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,284.046,224.8Zm-110.45,0a34.114,34.114,0,1,0,34.317,34.113A34.217,34.217,0,0,0,173.6,224.8Zm220.923,0a34.114,34.114,0,1,0,34.317,34.113A34.215,34.215,0,0,0,394.519,224.8Zm153.807-55.319c-15.535-24.172-37.31-45.57-64.681-63.618-52.886-34.817-122.374-54-195.666-54a405.975,405.975,0,0,0-72.032,6.357,238.524,238.524,0,0,0-49.51-36.588C99.684-11.7,40.859.711,11.135,11.421A14.291,14.291,0,0,0,5.58,34.782C26.542,56.458,61.222,99.3,52.7,138.252c-33.142,33.9-51.112,74.776-51.112,117.337,0,43.372,17.97,84.248,51.112,118.148,8.526,38.956-26.154,81.816-47.116,103.491a14.284,14.284,0,0,0,5.555,23.34c29.724,10.709,88.549,23.147,155.324-10.2a238.679,238.679,0,0,0,49.51-36.589A405.972,405.972,0,0,0,288,460.14c73.313,0,142.8-19.159,195.667-53.975,27.371-18.049,49.145-39.426,64.679-63.619,17.309-26.923,26.07-55.916,26.07-86.125C574.394,225.4,565.634,196.43,548.326,169.485ZM284.987,409.9a345.65,345.65,0,0,1-89.446-11.5l-20.129,19.393a184.366,184.366,0,0,1-37.138,27.585,145.767,145.767,0,0,1-52.522,14.87c.983-1.771,1.881-3.563,2.842-5.356q30.258-55.68,16.325-100.078c-32.992-25.962-52.778-59.2-52.778-95.4,0-83.1,104.254-150.469,232.846-150.469s232.867,67.373,232.867,150.469C517.854,342.525,413.6,409.9,284.987,409.9Z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						{{ this.$langStore.lang == 'en' ? 'Support' : 'Поддержка' }}					</span>
				</h3>
									<p class="elementor-icon-box-description" style="text-align: justify;">
										{{ this.$langStore.lang == 'en' ? 'Reliable support empowers client success, offering assistance throughout intricate trade processes.' : 'Надежная поддержка способствует успеху клиентов, предлагая помощь в сложных торговых процессах.' }}					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "AdvantagesComponent"
  }
  </script>
  
  <style scoped lang="scss">
  
  </style>