<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-8d2e3e1 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="8d2e3e1" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffec9a7" data-id="ffec9a7" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-b9f3a19 elementor-widget elementor-widget-heading" data-id="b9f3a19" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">{{ this.$langStore.lang == 'ru' ? 'Свяжитесь с нами' : 'GET IN TOUCH WITH US' }}</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-8f711f9 elementor-button-align-stretch elementor-widget elementor-widget-form" data-id="8f711f9" data-element_type="widget" data-settings="{&quot;button_width&quot;:&quot;50&quot;,&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
				<div class="elementor-widget-container">
			<form class="elementor-form" method="post" name="New Form">
			<input type="hidden" name="post_id" value="338"/>
			<input type="hidden" name="form_id" value="8f711f9"/>
			<input type="hidden" name="referer_title" value="About Us" />

							<input type="hidden" name="queried_id" value="338"/>
			
			<div class="elementor-form-fields-wrapper elementor-labels-above">
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-25">
												<label for="form-field-name" class="elementor-field-label">
													{{ this.$langStore.lang == 'en' ? 'Name' : 'Имя' }}							</label>
														<input size="1" type="text" name="form_fields[name]" id="form-field-name" class="elementor-field elementor-size-sm  elementor-field-textual" >
											</div>
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_7788185 elementor-col-25">
												<label for="form-field-field_7788185" class="elementor-field-label">
													{{ this.$langStore.lang == 'en' ? 'Company' : 'Компания' }}							</label>
														<input size="1" type="text" name="form_fields[field_7788185]" id="form-field-field_7788185" class="elementor-field elementor-size-sm  elementor-field-textual" >
											</div>
								<div class="elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-field_9c095a4 elementor-col-25">
												<label for="form-field-field_9c095a4" class="elementor-field-label">
													{{ this.$langStore.lang == 'en' ? 'Phone' : 'Телефон' }}							</label>
								<input size="1" type="tel" name="form_fields[field_9c095a4]" id="form-field-field_9c095a4" class="elementor-field elementor-size-sm  elementor-field-textual" pattern="[0-9()#&amp;+*-=.]+" title="Only numbers and phone characters (#, -, *, etc) are accepted.">

						</div>
								<div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-email elementor-col-25 elementor-field-required">
												<label for="form-field-email" class="elementor-field-label">
								Email							</label>
														<input size="1" type="email" name="form_fields[email]" id="form-field-email" class="elementor-field elementor-size-sm  elementor-field-textual" required="required" aria-required="true">
											</div>
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_7f34cb6 elementor-col-50">
												<label for="form-field-field_7f34cb6" class="elementor-field-label">
													{{ this.$langStore.lang == 'en' ? 'Your Question' : 'Ваш Вопрос' }}							</label>
														<input size="1" type="text" name="form_fields[field_7f34cb6]" id="form-field-field_7f34cb6" class="elementor-field elementor-size-sm  elementor-field-textual">
											</div>
								<div class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-50 e-form__buttons">
					<button type="submit" class="elementor-button elementor-size-sm">
						<span >
															<span class=" elementor-button-icon">
																										</span>
																						<span class="elementor-button-text" style="text-transform: uppercase; font-weight: bold;">{{ this.$langStore.lang == 'en' ? 'Send' : 'Отправить' }}</span>
													</span>
					</button>
				</div>
			</div>
		</form>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "FeedbackFormComponent"
  }
  </script>
  
  <style scoped lang="scss">
	
  </style>