<template>
	<div class="news-wrapper">
		<img :src="news.photo" class="news-photo">
		<h2>{{ news.text }}</h2>
		<div class="news-content">{{ news.text_full }}</div>
	</div>
</template>

<script>
export default {
    name: "NewsView",
	data() {
			return {
				news: [],
			}
		},
	mounted() {
		this.fetchContent();
	},
	methods: {
		async fetchContent() {
		try {
			const response = await fetch(process.env.VUE_APP_API_URL + `/api/news/get/` + this.$route.params.id);
			if (!response.ok) throw new Error('Ошибка запроса');
			const data = await response.json();
			this.news = data;
		} catch (error) {
			console.error('Ошибка при получении данных:', error);
		}
		}
	}
}
</script>

<style scoped lang="scss">
	.news-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		padding: 2rem 5rem;

		.news-photo {
			max-width: 10rem;
		}

		h2 {
			text-align: center;
		}

		.news-content {
			text-align: center;
		}
	} 
</style>