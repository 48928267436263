<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-1de635e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="1de635e" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;}">
						<div class="elementor-container elementor-column-gap-default" style="text-align: justify">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-41c37e5" data-id="41c37e5" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-08c868a elementor-widget elementor-widget-heading" data-id="08c868a" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default" style="text-align: center;">{{ this.$langStore.lang == 'en' ? 'BENEFITS OF' : 'ПРЕИМУЩЕСТВА' }} <span style="color:#445c71">{{ this.$langStore.lang == 'en' ? 'TRADE HOUSE MALAYSIA' : 'ТОРГОВОГО ДОМА МАЛАЙЗИЯ' }}</span></h2>		</div>
				</div>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-23900d7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="23900d7" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default" style="text-align: justify;">
					<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7c8091f" data-id="7c8091f" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-be0809c elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="be0809c" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Strategic Location' : 'Стратегическое расположение' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' ? 
			'Malaysia\'s strategic location in the heart of Southeast Asia provides businesses with easy access to the region\'s growing markets. Well-connected transportation infrastructure facilitates trade and commerce, including major ports in Klang and Penang.' 
			: 'Стратегическое расположение Малайзии в самом сердце Юго-Восточной Азии обеспечивает предприятиям легкий доступ к растущим рынкам региона. Хорошо развитая транспортная инфраструктура способствует торговле и коммерции, включая крупные порты в Кланге и Пенанге.' }}
</p></div></div>		</div>
				</div>
				<div class="elementor-element elementor-element-768c696 elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="768c696" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Pro-Business Policies' : 'Политика поддержки бизнеса' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia has implemented pro-business policies, including initiatives to attract foreign direct investment (FDI). The government\'s commitment to economic liberalization and initiatives like the Malaysia Digital Economy Blueprint create a favorable environment for businesses.' 
			: 'Малайзия реализовала политику поддержки бизнеса, включая инициативы по привлечению прямых иностранных инвестиций (ПИИ). Приверженность правительства экономической либерализации и такие инициативы, как План цифровой экономики Малайзии, создают благоприятную среду для бизнеса.' }}</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e61a9e5" data-id="e61a9e5" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-6444f7e elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="6444f7e" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Robust Infrastructure' : 'Надежная инфраструктура' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Kuala Lumpur, as the capital, boasts modern infrastructure and facilities conducive to business operations. Well-developed transport networks, including highways and an efficient public transportation system, enhance logistics and connectivity.' 
			: 'Куала-Лумпур, как столица, может похвастаться современной инфраструктурой и удобствами, способствующими ведению бизнеса. Хорошо развитые транспортные сети, включая автомагистрали и эффективную систему общественного транспорта, улучшают логистику и транспортное сообщение.' }}
</p></div></div>		</div>
				</div>
				<div class="elementor-element elementor-element-ed67c4d elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="ed67c4d" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Diverse Economy' : 'Разнообразная экономика' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia\'s diverse economy, encompassing industries like manufacturing, services, and technology, provides a range of opportunities for businesses. The government\'s emphasis on promoting sectors like digital technology and renewable energy opens up new avenues for growth.' 
			: 'Разнообразная экономика Малайзии, охватывающая такие отрасли, как производство, услуги и технологии, предоставляет целый ряд возможностей для бизнеса. Акцент правительства на развитии таких секторов, как цифровые технологии и возобновляемые источники энергии, открывает новые возможности для роста.' }}
</p></div></div>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-d5a34cb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d5a34cb" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default" style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 2rem;">
						<div class="elementor-element elementor-element-43d2599 elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="43d2599" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Skilled Workforce' : 'Квалифицированная рабочая сила' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia has a well-educated and skilled workforce, particularly in areas like technology, engineering, and finance. Kuala Lumpur, being a financial hub, attracts professionals with diverse expertise, contributing to a competitive and capable talent pool.' 
			: 'Малайзия имеет хорошо образованную и квалифицированную рабочую силу, особенно в таких областях, как технологии, инженерное дело и финансы. Куала-Лумпур, являясь финансовым центром, привлекает профессионалов с разнообразным опытом, способствуя формированию конкурентоспособного и способного кадрового резерва.' }}
</p></div></div>		</div>
				</div>
				<div class="elementor-element elementor-element-75bdb0d elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="75bdb0d" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Political Stability' : 'Политическая стабильность' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia is known for its political stability, providing a secure and predictable environment for businesses to thrive. A stable political climate contributes to investor confidence and long-term planning.' 
			: 'Малайзия известна своей политической стабильностью, обеспечивающей безопасную и предсказуемую среду для процветания бизнеса. Стабильный политический климат способствует доверию инвесторов и долгосрочному планированию.' }}
</p></div></div>		</div>
				</div>
						<div class="elementor-element elementor-element-a2bf5e4 elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="a2bf5e4" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Multilingual Environment' : 'Многоязычная среда' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia\'s multilingual environment, where English is widely spoken, facilitates communication and collaboration for international businesses. This linguistic diversity is an advantage for companies engaging in global trade and partnerships.' 
			: 'Многоязычная среда Малайзии, где широко распространен английский язык, облегчает общение и сотрудничество для международного бизнеса. Такое языковое разнообразие является преимуществом для компаний, занимающихся глобальной торговлей и партнерством.' }}
</p></div></div>		</div>
				</div>
				<div class="elementor-element elementor-element-b39cabc elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="b39cabc" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Quality of Life' : 'Качество жизни' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Cities like Kuala Lumpur offer a high quality of life, with modern amenities, healthcare facilities, and educational institutions. This makes it an attractive destination for expatriates and contributes to a positive work environment.' 
			: 'Такие города, как Куала-Лумпур, предлагают высокое качество жизни, современные удобства, медицинские учреждения и образовательные учреждения. Это делает его привлекательным местом для иностранцев и способствует созданию позитивной рабочей среды.' }}
</p></div></div>		</div>
				</div>
						<div class="elementor-element elementor-element-342923b elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="342923b" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Investment Incentives' : 'Инвестиционные стимулы' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'The Malaysian government offers various incentives to attract and support businesses, including tax incentives, grants, and other financial assistance programs. Incentives for research and development (R&D) activities and technology-driven businesses contribute to innovation and competitiveness.' 
			: 'Правительство Малайзии предлагает различные стимулы для привлечения и поддержки бизнеса, включая налоговые льготы, гранты и другие программы финансовой помощи. Стимулы к исследованиям и разработкам (НИОКР) и технологически ориентированному бизнесу способствуют инновациям и конкурентоспособности.' }}
</p></div></div>		</div>
				</div>
				<div class="elementor-element elementor-element-88188dc elementor-vertical-align-top elementor-widget elementor-widget-image-box" data-id="88188dc" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">{{ this.$langStore.lang == 'en' ? 'Cultural Diversity' : 'Культурное разнообразие' }}</h3><p class="elementor-image-box-description">{{ this.$langStore.lang == 'en' 
			? 'Malaysia\'s rich cultural diversity creates a vibrant and inclusive business environment. The fusion of cultures in places like Penang provides a unique setting for businesses to understand and cater to diverse markets.' 
			: 'Богатое культурное разнообразие Малайзии создает яркую и инклюзивную деловую среду. Слияние культур в таких местах, как Пенанг, предоставляет предприятиям уникальные условия для понимания и обслуживания разнообразных рынков.' }}
</p></div></div>		</div>
				</div>
					</div>
		</section>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "BenefitsComponent"
  }
  </script>
  
  <style scoped lang="scss">
  p {
	text-align: justify !important;
  }
  </style>